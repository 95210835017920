<script setup lang="ts">
	import Navbar from "~/components/Navigation/Navbar.vue";
	import Footer from "~/components/Navigation/Footer.vue";
	const head = useLocaleHead({
		addDirAttribute: true,
		identifierAttribute: "id",
		addSeoAttributes: true,
	});
</script>
<template>
	<div>
		<Html :lang="head.htmlAttrs.lang">
			<Body>
				<div class="flex flex-column min-h-screen">
					<Navbar />
					<div class="flex-grow-1">
						<slot />
					</div>
					<Footer />
				</div>
			</Body>
		</Html>
	</div>
</template>
